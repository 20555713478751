import React from "react";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";

import InstagramAuth from "../../components/Admin/InstagramAuth";

import styles from "./styles.module.less";

const palette = {
    primary: { main: "#182539" },
    secondary: { main: "#FF8A00" },
};
const themeName = "Bay Equity Material Theme";

const theme = createMuiTheme({ palette, themeName });

export default props => (
    <MuiThemeProvider theme={theme}>
        <div class={styles.Instagram}>
            <InstagramAuth />
        </div>
    </MuiThemeProvider>
);
