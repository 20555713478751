import React, { Component } from "react";
import querystring from "querystring";

import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

import Button from "@material-ui/core/Button";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Input from "@material-ui/core/Input";
import Divider from "@material-ui/core/Divider";

import "./styles.module.less";

const instaAuth = `https://api.instagram.com/oauth/authorize/?client_id=${process.env.GATSBY_INSTAGRAM_CLIENT_KEY}&redirect_uri=${process.env.GATSBY_INSTAGRAM_REDIRECT_URL}&response_type=token`;

export default class InsgagramAuth extends Component {
    state = {
        accessToken: "",
        error: "",
        errorDescription: "",
        isCloseable: false,
    };

    componentDidMount() {
        this.handleUrl();
        this.isCloseable();
    }

    handleUrl = () => {
        // get a new URL object so we don't break things
        let url = new URL(window.location);
        let hash = url.hash.replace("#", ""); // we don't need that octothorpe
        let error = url.searchParams.get("error"),
            errorDescription = url.searchParams.get("error_description");
        let accessToken = querystring.parse(hash).access_token || "";
        this.setState({
            error,
            errorDescription,
            accessToken,
        });
    };

    isCloseable = () => {
        if (window && window.opener) {
            this.setState({ isCloseable: true });
        }
    };

    handleFocus = e => {
        e.target.select && e.target.select();
    };

    handleClose = e => {
        if (this.state.isCloseable) {
            // get reference to input field and set access token as value
            let input = window.opener.document.querySelector(
                "input[name='instagramToken']",
            );
            if (input) {
                input.value = this.state.accessToken;
            }
            window.close();
        }
    };

    renderAccesstoken = () => {
        if (!this.state.accessToken) return null;
        return (
            <Box marginTop={3}>
                <Typography component="h2" variant="h6">
                    Your Instagram Access Token
                </Typography>
                <Typography component="p" variant="body2" paragraph>
                    Please copy and paste this token into the appropriate field
                    on your LO Profile in the CMS.
                </Typography>
                <Input
                    type="text"
                    readOnly
                    fullWidth={true}
                    value={this.state.accessToken}
                    onFocus={this.handleFocus}
                />
                {this.state.isCloseable && (
                    <Box marginTop={3}>
                        <Divider />
                        <Button onClick={this.handleClose}>
                            <ArrowBackIcon />
                            Return to Profile
                        </Button>
                    </Box>
                )}
            </Box>
        );
    };

    renderErrors = () => {
        if (!this.state.error) return null;
        return (
            <div>
                <h2>Instagram Authorization failed:</h2>
                <p>{this.state.errorDescription}</p>
            </div>
        );
    };

    renderButton = () => {
        if (this.state.accessToken || this.state.error) return null;
        return (
            <Button href={instaAuth} variant="contained" color="primary">
                <OpenInNewIcon />
                Authorize Instagram
            </Button>
        );
    };

    render() {
        return (
            <div className="InstagramAuth">
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography component="h1" variant="h6">
                            Authorize Instagram
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box padding={3}>
                    <Box component="header" marginBottom={3}>
                        <Typography component="h1" variant="h5">
                            Generate an Instagram Access Token
                        </Typography>
                        <Typography component="h2" variant="subtitle1">
                            Instagram requires authentication
                        </Typography>
                    </Box>

                    <Typography component="p" variant="body2" paragraph>
                        In order to embed your Instagram feed on our website,
                        Instagram requires that you authorize access for this
                        web site.
                    </Typography>
                    <Typography component="p" variant="body2" paragraph>
                        The link below will take you to Instagram and ask you
                        for basic Instagram account access.
                    </Typography>
                    <Typography component="p" variant="body2" paragraph>
                        This will grant this website the authority to read your
                        public profile information and read (and display) your
                        Instagram feed.
                    </Typography>
                    <Typography component="p" variant="body2" paragraph>
                        You may revoke this access at any time via your
                        Instagram app or{" "}
                        <a href="https://www.instagram.com/accounts/manage_access/">
                            website
                        </a>
                        .
                    </Typography>

                    <Paper>
                        <Box p={3}>
                            {this.renderButton()}
                            {this.renderAccesstoken()}
                            {this.renderErrors()}
                        </Box>
                    </Paper>
                </Box>
            </div>
        );
    }
}
